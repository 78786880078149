var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-crud-table',{attrs:{"headers":_vm.analysisServerColumns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total},on:{"pageChanged":function (page) {
      _vm.crudPaginationMX().change(page);
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("网点分析服务器")]),_c('v-spacer'),(_vm.hasPermission(['hardware.psm.add']))?_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"添加"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, _vm.onOpenEdit(_vm.crud.default))}}}):_vm._e(),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title},on:{"save":function () {
            _vm.crudActionMX().saveItem();
          },"close":function () {
            _vm.crudActionMX().closeEditingPage(_vm.crud);
          }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-column"},[_c('k-tree-select',{staticClass:"my-1",attrs:{"treeKey":"uuid","placeholder":"请选择机构名称","label":"机构名称","rules":[_vm.globalRules.multiple],"items":_vm.organizationList},model:{value:(_vm.cruddataMX.data.organizationUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "organizationUuid", $$v)},expression:"cruddataMX.data.organizationUuid"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"型号"},model:{value:(_vm.cruddataMX.data.psmName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "psmName", $$v)},expression:"cruddataMX.data.psmName"}},[_vm._t("default",function(){return [_vm._v("*")]})],2),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"IP","required":"","rules":_vm.ipAddrRules},model:{value:(_vm.cruddataMX.data.ipAddr),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "ipAddr", $$v)},expression:"cruddataMX.data.ipAddr"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"SN"},model:{value:(_vm.cruddataMX.data.sn),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "sn", $$v)},expression:"cruddataMX.data.sn"}})],1)])]},proxy:true}],null,true),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"runningStatusName",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('span',{staticStyle:{"color":"#fe9a07","font-size":"30px"}},[_vm._v("·")]),_c('div',[_vm._v("未知")])])]},proxy:true},{key:"healthStatusName",fn:function(ref){
          var item = ref.item;
return [(item.healthStatus === '01')?_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('div',{staticStyle:{"color":"#1fc97a"}},[_vm._v(_vm._s(item.healthStatusName))])]):_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('div',{staticStyle:{"color":"#f5194d"}},[_vm._v(_vm._s(item.healthStatusName))])])]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('k-Secondary-btn',{attrs:{"mall":true,"text":"重启"},on:{"click":function($event){return _vm.reStart(item)}}}),(_vm.hasPermission(['hardware.psm.edit']))?_c('k-table-warning-btn',{attrs:{"text":"修改"},on:{"click":function($event){_vm.crudActionMX().editItem(item, function () { return _vm.onOpenEdit(item); })}}}):_vm._e(),(_vm.hasPermission(['hardware.psm.delete']))?_c('k-table-error-btn',{attrs:{"text":"删除"},on:{"click":function($event){_vm.crudActionMX().deleteItem(item)}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }