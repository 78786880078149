<template>
  <k-crud-table
    :headers="analysisServerColumns"
    :items="cruddataMX.dataList"
    :value="cruddataMX.pagination.current"
    :total="cruddataMX.pagination.total"
    @pageChanged="
      (page) => {
        crudPaginationMX().change(page);
      }
    "
  >
    <template #top>
      <v-toolbar flat>
        <div class="text-h5">网点分析服务器</div>
        <v-spacer></v-spacer>
        <k-primary-button
          v-if="hasPermission(['hardware.psm.add'])"
          icon="mdi-plus"
          text="添加"
          @click="
            crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
          "
        />
        <k-crud-dialog
          v-model="crud.switch"
          :title="crud.title"
          @save="
            () => {
              crudActionMX().saveItem();
            }
          "
          @close="
            () => {
              crudActionMX().closeEditingPage(crud);
            }
          "
        >
          <template #dialogContent>
            <v-form :ref="crud.form.ref">
              <div class="d-flex flex-column">
                <k-tree-select
                  v-model="cruddataMX.data.organizationUuid"
                  treeKey="uuid"
                  placeholder="请选择机构名称"
                  class="my-1"
                  label="机构名称"
                  :rules="[globalRules.multiple]"
                  :items="organizationList"
                />
                <!-- <k-select
                  v-model="cruddataMX.data.organizationUuid"
                  :items="organizationList"
                  label="机构名称"
                  :loading="crud.select.roles.loading"
                  :disabled="crud.select.roles.loading"
                  class="my-2"
                /> -->
                <k-text-input
                  v-model="cruddataMX.data.psmName"
                  label="型号"
                  class="my-2"
                >
                  <slot>*</slot>
                </k-text-input>
                <k-text-input
                  v-model="cruddataMX.data.ipAddr"
                  label="IP"
                  class="my-2"
                  required
                  :rules="ipAddrRules"
                />
                <k-text-input
                  v-model="cruddataMX.data.sn"
                  label="SN"
                  class="my-2"
                />
              </div>
            </v-form>
          </template>
        </k-crud-dialog>
      </v-toolbar>
    </template>
    <template #runningStatusName>
      <!--      <div-->
      <!--        v-if="item.runningStatus === '01'"-->
      <!--        style="display: flex; align-items: center"-->
      <!--      >-->
      <!--        <span style="color: #1fc97a; font-size: 30px">·</span>-->
      <!--        <div>{{ item.runningStatusName }}</div>-->
      <!--      </div>-->

      <div style="display: flex; align-items: center;justify-content: center">
        <span style="color: #fe9a07; font-size: 30px">·</span>
        <div>未知</div>
      </div>
    </template>
    <template #healthStatusName="{ item }">
      <div
        v-if="item.healthStatus === '01'"
        style="display: flex; align-items: center;justify-content: center"
      >
        <div style="color: #1fc97a">{{ item.healthStatusName }}</div>
      </div>

      <div v-else style="display: flex; align-items: center;justify-content: center">
        <div style="color: #f5194d">{{ item.healthStatusName }}</div>
      </div>
    </template>
    <template #actions="{ item }">
      <k-Secondary-btn
        :mall="true"
        text="重启"
        @click="reStart(item)"
      ></k-Secondary-btn>
      <k-table-warning-btn
        v-if="hasPermission(['hardware.psm.edit'])"
        text="修改"
        @click="crudActionMX().editItem(item, () => onOpenEdit(item))"
      >
      </k-table-warning-btn>
      <k-table-error-btn
        v-if="hasPermission(['hardware.psm.delete'])"
        text="删除"
        @click="crudActionMX().deleteItem(item)"
      ></k-table-error-btn>
    </template>
  </k-crud-table>
</template>
<script>
export default {
  props: {
    value: undefined,
    dosearchflg: { type: Boolean, default: true },
  },
  data() {
    return {
      // organizationUuid: [],
      crud: {
        name: '网点分析服务器',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm2',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },

        default: {
          organizationUuid: [],
          psmName: '',
          ipAddr: '',
          sn: '',
        },
      },
      analysisServerColumns: [
        { text: '序号', value: 'indexNum', sortable: false, align: 'center' },
        {
          text: '机构名称',
          value: 'orgName',
          sortable: false,
          align: 'center',
        },
        { text: '型号', value: 'psmName', sortable: false, align: 'center' },
        { text: 'IP', value: 'ipAddr', sortable: false, align: 'center' },
        { text: 'SN', value: 'sn', sortable: false, align: 'center' },
        {
          text: '软件版本',
          value: 'version',
          sortable: false,
          align: 'center',
        },
        { text: '监控', value: 'monitor', sortable: false, align: 'center' },
        {
          text: '运行状态',
          value: 'runningStatusName',
          sortable: false,
          align: 'center',
        },
        {
          text: '健康状态',
          value: 'healthStatusName',
          sortable: false,
          align: 'center',
        },
        {
          text: '分析项目',
          value: 'analysis',
          sortable: false,
          align: 'center',
          width: '350px',
        },
        {
          text: '操作',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '250px',
        },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
      },
      ipAddrRules: [
        (v) => !!v || 'IP不能为空',
        (v) =>
          /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/.test(
            v
          ) || 'IP地址不规范',
      ],
      SNRules: [
        (v) => !!v || 'SN不能为空',
        // (v) => /(^[A-Za-z0-9]{4,11}$)/.test(v) || 'SN不规范',
      ],
      psmNameRules: [
        (v) => !!v || '型号不能为空',
        // (v) => /(^[A-Za-z0-9]{1,5}$)/.test(v) || '型号不规范',
      ],
      organizationList: [],
      childrenList: [],
    };
  },
  computed: {},
  watch: {
    value() {
      this.cruddataMX.conditions.searchParam = this.value;
    },
    dosearchflg() {
      this.crudConditionsMX().resetPage();
    },
  },
  created() {
    this.getOrTree();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.device.hardware.getDevHardwareAnalysisServer,
        add: (parmas) => {
          parmas.organizationUuid = parmas.organizationUuid.toString();
          return this.$api.device.hardware.addAnalysisServer(parmas);
        },
        edit: (parmas) => {
          parmas.organizationUuid = parmas.organizationUuid.toString();
          return this.$api.device.hardware.editAnalysisServer(parmas);
        },

        delete: this.$api.device.hardware.deleteAnalysisServer,
      },
      {},
      {
        add: ['organizationUuid', 'psmName', 'ipAddr', 'sn'],
        edit: ['uuid', 'organizationUuid', 'psmName', 'ipAddr', 'sn'],
      }
    );
  },
  methods: {
    onOpenEdit(item) {
      if (item.orgCode) {
        this.$set(this.cruddataMX, 'data', { ...item });
        // this.cruddataMX.data.organizationUuid = [item.orgCode];
      }
      item.roleUuids = [];
      item.roleVos?.forEach((p) => {
        item.roles.push(p.name);
        item.roleUuids.push(p.uuid);
      });
    },

    reStart() {
      this.$alert.success('设备重启');
    },
    getOrTree() {
      this.$api.organization.organization.getOrTree().then(({ code, data }) => {
        if (code === 200) {
          this.organizationList = data;
        }
      });
    },
  },
};
</script>
